@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,500;0,800;1,100;1,200;1,500;1,800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
}
